import { useTranslation } from "react-i18next";

declare type PaymentCardProps = {
    card: any
}

export default function TokenizedCard({ card }: PaymentCardProps) {
    const { t } = useTranslation(['application-in-progress']);

    return <>
        <div className="card col-md-9 col-sm-12 col-lg-5 invisible-card">
            <div className="row">
                <div className="col-5 card-label">
                    {t('receive_payment.card.name-on-card')}
                </div>
                <div className="col-7">
                    {card.contact?.first_name} {card.contact?.last_name}
                </div>
                <div className="col-5 card-label">
                    {t('receive_payment.card.address')}
                </div>
                <div className="col-7">
                    {card.contact?.street_address}, {card.contact?.city},  {card.contact?.region}
                </div>
                <div className="col-5 card-label">
                    {t('receive_payment.card.session-id')}
                </div>
                <div className="col-7" id="virtual-card-number">
                    {card.card_token}
                </div>
            </div>
        </div>
    </>
}
