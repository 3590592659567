import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '@contexts/Authentication';
import { Logo } from '@icons/FlexPayTheme';
import { whiteLabelLogo } from '@icons';
import { GlobalContext } from '@contexts/Global';
import { SignOutModal } from './components';
import { AdminTypes } from '@type';
import { ModalBookingFailed } from '@pages/ApplicationInProgress/components';
import './header.scss';

export default function Header(props: any) {
    const { logout, selfAdminType } = useContext(AuthenticationContext);
    const { refs: { leaveConfirmationModal }, globalData: { currentPartner, currentPartnerConfig, offerStatus }, handleBlockRefresh } = useContext(GlobalContext);

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(['navbar', 'common']);
    const signOutModalRef = useRef<any>();
    const bookingFailedModal = useRef<any>();

    const leavingSession = useRef(false);

    useEffect(() => {
        if (!location) return;

        // If we hit a location where we dont need to use the order id, then
        // we should remove it from the session
        const URLS_TO_PRESERVE_ORDER_ID = [
            '/order',
            '/application'
        ]

        if (!URLS_TO_PRESERVE_ORDER_ID.some(url => location.pathname.includes(url)))
            sessionStorage.removeItem('order_id');
    }, [location])

    const handleFinishSession = useCallback(async () => {
        if (leavingSession.current) return;

        leavingSession.current = true
        try {
            await logout();
        } catch(ex) {
            console.error('Error logging out', ex);
        }

        window.removeEventListener('beforeunload', handleBlockRefresh);
        window.location.reload();
    }, [logout, handleBlockRefresh])


    const handleLogout  = useCallback(() => {
        const reg = /(\d+)\/order/g;

        if ('receive_payment' === offerStatus) {
            bookingFailedModal.current.showModal();
        } else if (history.location.pathname.match(reg)) {
            leaveConfirmationModal.current.blockedLocation = '';
            leaveConfirmationModal.current.actions = [async () => {
                await handleFinishSession();
            }]
            leaveConfirmationModal.current.showModal(() => {
                leaveConfirmationModal.current.actions = [];
            });
        } else {
            signOutModalRef.current.showModal();
        }

        // window.location.reload();
    }, [history, offerStatus, leaveConfirmationModal, handleFinishSession]);

    const redirect = useCallback((path: string) => {
        if (!history) return;
        history.push(path);
    }, [history]);

    return (
        <nav className="navbar navbar-expand-sm navbar-light bg-white justify-content-between">
            { (location.pathname.includes('/partner-select') || !currentPartner?.hasWhiteLabel) &&
                <div id="navbar-brand-container">
                    <span id="flexpay-logo" className="navbar-brand">
                        <Logo width="116" height="60"/>
                    </span>
                    {
                        currentPartner && !currentPartnerConfig?.onboardSpend && history.location.pathname !== "/partner-select"&& <React.Fragment>
                                <span className="has-partner-with"> {t('has-partner-with')} </span>
                                <img src={`https://cdn.uplift.com/merchant/${currentPartner.countries[0].UPC}/logo.png`} alt={currentPartner.countries[0].UPC} className="partner-logo"/>
                            </React.Fragment>
                    }
                </div>
            }

            {
                currentPartner?.hasWhiteLabel && location.pathname.includes('/application') &&
                <div id="navbar-brand-container">
                    <span id="flexpay-logo" className="navbar-brand">
                        { React.createElement(whiteLabelLogo(currentPartner.countries[0].UPC)) }
                    </span>
                </div>
            }
            <button aria-expanded="false" type="button" aria-controls="#nav-list" className="navbar-toggler" aria-label="Toggle navigation" data-target="#nav-list" data-toggle="collapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="nav-list">
                <ul className="navbar-nav align-items-center">
                    {
                        selfAdminType === AdminTypes.omniAdmin &&
                        <li  className="nav-item">
                            <button type="button" id="tenant-management" className="main-nav-link" onClick={() => redirect('/partners')}>{t('tenant-management')}</button>
                        </li>
                    }
                    {
                        selfAdminType !== AdminTypes.noAdmin &&
                        <li  className="nav-item">
                            <button type="button" id="user-management" className="main-nav-link" onClick={() => redirect('/agents')}>{t('user-management')}</button>
                        </li>
                    }
                    <li  className="nav-item">
                        <button type="button" id="new-application" className="main-nav-link" onClick={() => redirect('/partner-select')}>{t('new-app')}</button>
                    </li>
                    <li  className="nav-item" onClick={() => redirect('/security')}>
                        <button type="button" id="security" className="main-nav-link">{t('security')}</button>
                    </li>
                    <li  className="nav-item" onClick={handleLogout}>
                        <button type="button" id="confirm-sign-out" className="main-nav-link">{t('sign-out')}</button>
                    </li>
                </ul>
            </div>

            <SignOutModal ref={signOutModalRef} onConfirmSignOut={handleFinishSession}/>
            <ModalBookingFailed ref={bookingFailedModal} />
        </nav>
    )
}
