import { useTranslation } from 'react-i18next';

export default function SubventionPromo() {
    const { t } = useTranslation(['estimates-calculator']);

    return (
        <div className='subvention-promo p-0'>
            <div className='promo-label'>{t('regular-application.subvention-promo')}</div>
        </div>
    )
}