import { Copy } from "@components";
import { useTranslation } from "react-i18next";

declare type PaymentCardProps = {
    card: any
}

export default function VirtualCard({ card }: PaymentCardProps) {
    const { t } = useTranslation(['application-in-progress']);

    return (<>
        <div className="card col-md-9 col-sm-12 col-lg-5 invisible-card">
            <div className="row">
                <div className="col-5 card-label" data-qa="receive-payment-card-name-label">
                    {t('receive_payment.card.name-on-card')}
                </div>
                <div className="col-7 card-value" data-qa="receive-payment-card-name-value">
                    {card?.contact?.first_name} {card?.contact?.last_name}
                </div>
                <div className="col-5 card-label" data-qa="receive-payment-address-label">
                    {t('receive_payment.card.address')}
                </div>
                <div className="col-7 card-value" data-qa="receive-payment-address-value">
                    {card?.contact?.street_address}, {card?.contact?.city},  {card?.contact?.region}
                </div>
            </div>
        </div>

        <div className="card col-md-9 col-sm-12 col-lg-5">
            <div className="row">
                <div className="col-md-5 card-label col-sm-12">
                    {t('receive_payment.card.card-type')}
                </div>
                <div className="col-md-7 col-sm-12 card-value" id="virtual-card-type">
                    {card?.card_type}
                </div>
                <div className="col-md-5 card-label col-sm-12">
                    {t('receive_payment.card.card-number')}
                </div>
                <div className="col-md-7 col-sm-12 card-value" id="virtual-card-number">
                    {card?.card_number.match(/.{1,4}/g).join(" ")} <Copy textToCopy={card?.card_number} />
                </div>
                <div className="col-md-5 card-label col-sm-12">
                    {t('receive_payment.card.expiration')}
                </div>
                <div className="col-md-7 col-sm-12 card-value" id="virtual-card-expiration">
                    {card?.expiration_month}/{card?.expiration_year} <Copy textToCopy={`${card?.expiration_month}/${card?.expiration_year}`} />
                </div>
                <div className="col-md-5 card-label col-sm-12">
                    {t('receive_payment.card.security-code')}
                </div>
                <div className="col-md-7 col-sm-12 card-value" id="virtual-card-security-code">
                    {card?.card_ccv} <Copy textToCopy={card?.card_ccv} />
                </div>
                <div className="col-md-5 card-label col-sm-12">
                    {t('receive_payment.card.billing-zip')}
                </div>
                <div className="col-md-7 col-sm-12 card-value" id="virtual-card-postal-code">
                    {card?.contact?.postal_code} <Copy textToCopy={card?.contact?.postal_code} />
                </div>
            </div>
        </div>
    </>)
}