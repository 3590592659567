import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import useTabiService from '@hooks/useTabiService';
import { GlobalContext, GlobalData } from '@contexts/Global';

import './receivePayment.scss';
import { useTracking } from '@hooks/useTracking';
import { formatCurrency, partnerTypeByTripInfo } from '@helpers/Utils';
import { APPLICATION_STATUSES, DEMO_CARD, DEMO_CARD_OVERRIDE, DEMO_CARD_OVERRIDE_CA, PartnerTypes } from '@helpers/Constants';
import useParamsFromSessionStorage from '@hooks/useParamsFromSessionStorage';
import { Loading } from '@components';
import { Trans, useTranslation } from 'react-i18next';
import { applicationInProgressIcon, UpliftMisc } from '@icons';
import JsBarcode from 'jsbarcode';
import { trigger } from '@helpers/CustomEvents';
import PaymentAlert from '../Alert/alert'
import PaymentConfirmationDialogue from './paymentConfirmationDialogue';
import ModalBookingCompleted from '../ModalBookingCompleted/modalBookingCompleted';
import ModalBookingFailed from '../ModalBookingFailed/modalBookingFailed';
import ModalBarcodeFullscreen from '../ModalBarcodeFullscreen/modalBarcodeFullscreen';
import TokenizedCard from './tokenizedCard';
import VirtualCard from './virtualCard';

export default function ReceivePayment() {
    const { globalData: { tripInfo: { localization: { language, currency, country }, ...tripInfo }, currentPartnerConfig, offerStatus, currentPartner }, setGlobalData } = useContext(GlobalContext);

    const [{ order_id: orderIdFromUrl }] = useParamsFromSessionStorage();
    const tabiApi = useTabiService();
    const { t } = useTranslation(['application-in-progress']);
    const { trackResponse, trackAxiosResult, trackCardMount, trackStepFinished, trackSubmit } = useTracking();

    const circleIcon = applicationInProgressIcon('circle');

    const [card, setCard] = useState<any>(null);
    const [partnerType, setPartnerType] = useState(PartnerTypes.Travel);
    const [loading, setLoading] = useState(false);
    const [cardString, setCardString] = useState<null | string>(null);
    const [confirmationIds, setConfirmationIds] = useState<string[]>(['']);

    const lockGetCard: any = useRef();
    const bookingCompleteModal: any = useRef();
    const bookingFailedModal: any = useRef();
    const barcodeFullScreenModal: any = useRef();

    let translationPrefix = "regular-application"
    if (currentPartnerConfig?.isTokenizedPayment)
        translationPrefix = "tokenized-application";
    else if (currentPartnerConfig?.onboardSpend)
        translationPrefix = "onboard-application";

    useEffect(() => {
        if (!trackCardMount) return;

        trackCardMount('complete-booking');
    }, [trackCardMount]);

    useEffect(() => {
        setPartnerType(
            partnerTypeByTripInfo({
                ...tripInfo,
                localization: {
                    language,
                    currency,
                    country
                }
            })
        )
    }, [tripInfo, language, currency, country])

    useEffect(() => {
        if (!tabiApi) return;
        if (!tripInfo?.order_amount) return;
        if (card) return;
        if (!orderIdFromUrl && !tripInfo.order_id) return;
        if (lockGetCard.current) return;
        if (!currentPartnerConfig) return;

        lockGetCard.current = true;
        const order_id = orderIdFromUrl || tripInfo.order_id

        const onGetCardResponse = ({ data: { wex } }: any) => {
            let wexToUse = wex;

            if (wex.card_number === DEMO_CARD) {
                wexToUse = (country === 'CA') ? DEMO_CARD_OVERRIDE_CA : DEMO_CARD_OVERRIDE
            }

            setCard(wexToUse);
            trackStepFinished('client-finished', tripInfo.order_amount, tripInfo);
            trackSubmit(order_id, wexToUse.card_token ? wexToUse.card_token : wexToUse.card_number, tripInfo);
        }

        if (currentPartnerConfig.isTokenizedPayment) {
            tabiApi.fetchToken(order_id)
                .then(onGetCardResponse)
                .finally(() => lockGetCard.current = false);
        }
        else {
            tabiApi.fetchCard(order_id)
                .then(onGetCardResponse)
                .finally(() => lockGetCard.current = false);

        }
    }, [tabiApi, card, setCard, tripInfo, trackStepFinished, trackSubmit, orderIdFromUrl, currentPartner, currentPartnerConfig, country]);

    useEffect(() => {
        if (!card) return;

        setCardString(`${card.card_token}`);
    }, [card])

    useEffect(() => {
        if (!cardString) return;
        if (!currentPartnerConfig) return
        if (!currentPartnerConfig.isTokenizedPayment) return

        JsBarcode("#barcode", cardString);
    }, [cardString, currentPartnerConfig])

    const onValidationSuccess = useCallback(() => {
        trackResponse(confirmationIds.join(','), orderIdFromUrl)
        tabiApi.confirmOrder(orderIdFromUrl, confirmationIds.join(','))
            .then((data: any) => {
                bookingCompleteModal.current?.showModal();

                tabiApi.offerStatus(orderIdFromUrl).then(({ data }: any) => {
                    setGlobalData((gd: GlobalData) => {
                        if (gd.offerStatus !== data.status) trackAxiosResult('status-poll', 'get', {
                            status: data.status,
                            'status-stack': data.status_stack,
                            order_id: orderIdFromUrl
                        })

                        return {
                            ...gd,
                            offerStatus: data.status,
                        }

                    });
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [tabiApi, orderIdFromUrl, confirmationIds, trackResponse, setGlobalData, trackAxiosResult])

    const handleCompleteBooking = useCallback(() => {
        // If we click the button for the second time, we just show the final modal
        if (offerStatus === APPLICATION_STATUSES.trip_booked) {
            bookingCompleteModal.current?.showModal();
            return;
        }

        setLoading(true);
        if (currentPartnerConfig?.hidePaymentConfirmation) {
            onValidationSuccess()
        } else {
            trigger('form:validate-inputs-confirmation-numbers', {});
        }

    }, [setLoading, currentPartnerConfig?.hidePaymentConfirmation, onValidationSuccess, offerStatus])

    const onValidationFail = () => {
        bookingFailedModal.current?.showModal();
        setLoading(false);
    }

    if (!(card && currentPartnerConfig)) return <Loading bigLoading />

    return (
        <div className="receive-payment">
            <p className="title">
                {t(`receive_payment.${translationPrefix}.title`, { kind: t(`receive_payment.${partnerType === PartnerTypes.Travel ? 'travel' : 'non-travel'}`) })}
            </p>
            <div className="box-content">
                <p className="instruction">
                    {t(`receive_payment.${translationPrefix}.use-the-virtual-card`)}
                </p>

                <div className="icon">
                    {circleIcon && React.createElement(circleIcon, null, '')}
                </div>

            </div>
            <div className="d-flex">
                <span className='left-border-dashed' />
                <div className="box-content flex-column px-2 my-0">
                    <PaymentAlert color="error">
                        <div className="alert-text">
                            <Trans
                                t={t}
                                i18nKey='receive_payment.do-not-close'
                                components={[
                                    <span className="enfasis-text" />,
                                ]}
                            />
                        </div>
                    </PaymentAlert>
                    <p>
                        <span>
                            <Trans
                                t={t}
                                i18nKey={`receive_payment.${translationPrefix}.card-use`}
                                values={{
                                    amount: formatCurrency(tripInfo.order_amount, language || 'en', currency || 'USD', 2, true),
                                    use: partnerType === PartnerTypes.Travel ?
                                        t(`receive_payment.${translationPrefix}.pay-for-the-item`) :
                                        t(`receive_payment.${translationPrefix}.complete-the-transaction`)
                                }}
                                components={[
                                    <span className="enfasis-text" />,
                                ]}

                            />
                        </span>
                    </p>
                    {card && (currentPartnerConfig.isTokenizedPayment) ? <TokenizedCard card={card} /> : <VirtualCard card={card} />
                    }

                    {!card && offerStatus === 'trip_booked' && <div className="card col-12">
                        <p>
                            {t('receive_payment.card-not-fetched-already-booked')}
                        </p>
                    </div>}

                    {currentPartnerConfig?.isTokenizedPayment &&
                        <div className='barcode-container' onClick={() => barcodeFullScreenModal.current.showModal()}>
                            <canvas id="barcode"></canvas>
                            <div className='barcode-fullscreen-icon'>
                                {React.createElement(UpliftMisc.OpenInFullIcon, null, null, '')}
                            </div>
                        </div>
                    }

                </div>
            </div>

            {!currentPartnerConfig?.hidePaymentConfirmation && <PaymentConfirmationDialogue
                partnerType={partnerType}
                onValidationSuccess={onValidationSuccess}
                onValidationFail={onValidationFail}
                confirmationIds={confirmationIds}
                setConfirmationIds={setConfirmationIds}
            />}

            <div className="box-content">
                <button className="btn btn-primary complete-booking"
                    disabled={loading}
                    onClick={handleCompleteBooking}>
                    {partnerType === PartnerTypes.Travel ? t('receive_payment.buttons.complete-upgrade-booking')
                        : t('receive_payment.buttons.complete-upgrade-purchase')}
                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                </button>
            </div>

            <ModalBookingCompleted ref={bookingCompleteModal} />
            <ModalBookingFailed ref={bookingFailedModal} />
            <ModalBarcodeFullscreen ref={barcodeFullScreenModal} cardString={cardString} />

        </div>
    )
}